const Mixpanel = require("mixpanel-browser");

const mixpanelToken = process.env.MIXPANEL_DISPATCHER_APP_TOKEN;
const railsEnv = process.env.RAILS_ENV;
const debugMode = railsEnv !== "production";
const {
  driver_company_id,
  driver_company_name,
  driver_company_user_id,
  driver_company_user_name,
  driver_company_user_email,
  driver_company_user_company_ids,
  mixpanel_blocked_users
} = window.gon;
const blockedUsers = mixpanel_blocked_users.split(",").map(id => parseInt(id, 10)).filter(Number.isInteger) 

if (mixpanelToken) {
  const mixpanelWrapper = {};
  let isInitialized = false;

  const debugModeConsoleLog = (message) => {
    if (debugMode) {
      console.log(message);
    }
  };

  const isUserBlocked = (userId) => blockedUsers.includes(userId);

  if (isUserBlocked(driver_company_user_id)) {
    debugModeConsoleLog("User is blocked. Mixpanel will not initialize.");
  } else {
    Mixpanel.init(mixpanelToken, {
      debug: debugMode,
      loaded: () => {
        isInitialized = true;
        
        if (driver_company_user_id) {
          Mixpanel.identify(driver_company_user_id);
  
          Mixpanel.people.set({
            $name: driver_company_user_name,
            $email: driver_company_user_email,
          });
        }
  
        if (driver_company_user_company_ids) {
          Mixpanel.set_group("company_id", driver_company_user_company_ids);
        }
  
        if (driver_company_id) {
          Mixpanel.get_group("company_id", driver_company_id).set_once({
            $name: driver_company_name,
          });
        }
      },
    });
  }

  /**
   * Tracks a mixpanel event
   * @param {string} eventName
   * @param {object} eventData
   */

  mixpanelWrapper.track = (eventName, eventData) => {
    if (isInitialized) {
      const distinctId = Mixpanel.get_distinct_id();
      
      if (isUserBlocked(distinctId)) {
        return null;
      }
      return Mixpanel.track(eventName, { ...eventData });
    }
    return null;
  };

  window.Mixpanel = { Mixpanel, ...mixpanelWrapper };
}
